import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchProduct,
  fetchProductByCategory,
  fetchProductById,
  fetchProductBySubCategoryId,
} from "./ProductAPI";

const initialState = {
  value: 0,
  status: "idle",
  productData: {
    status : 0,
    data:{  items : [],totalCount:0, itemsPerPage: 0,page : 0}
  },
  productBycategory: null,
  productById: null,
  adminproductData: [],
};


export const fetchProductAsync = createAsyncThunk(
  "product/fetchProduct",
  async (page) => {
    const response = await fetchProduct(page);

    return response.data;
  }
);

export const fetchProductByIdAsync = createAsyncThunk(
  "product/fetchProductById",
  async (productId) => {
    const response = await fetchProductById(productId);

    return response.data;
  }
);

export const fetchProductByCategoryAsync = createAsyncThunk(
  "product/fetchProductByCategory",
  async (category) => {
    const response = await fetchProductByCategory(category);

    return response.data;
  }
);

export const fetchProductBySubCategoryIdAsync = createAsyncThunk(
  "product/fetchProductBySubCategoryId",
  async (subcategoryId) => {
    const response = await fetchProductBySubCategoryId(subcategoryId);

    return response.data;
  }
);

export const ProductSlice = createSlice({
  name: "product",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productData.data.items.push(...action.payload?.data?.items);
        state.productData.status = action.payload?.status;
        state.productData.data.totalCount =action.payload?.data?.totalCount;
        state.productData.data.page = action.payload?.data?.page;
        state.productData.data.itemsPerPage = action.payload?.data?.itemsPerPage;
      })
      .addCase(fetchProductByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productById = action.payload;
      })
      
      .addCase(fetchProductByCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductByCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productBycategory = action.payload;
      })
      .addCase(fetchProductBySubCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductBySubCategoryIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productBycategory = action.payload;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectProduct = (state) => state.product.productData;
export const selectProductByCategory = (state) =>
  state.product.productBycategory;
export const selectProductById = (state) => state.product.productById;

export default ProductSlice.reducer;
