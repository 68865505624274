// A mock function to mimic making an async request for data
import axios from "axios";
export function fetchProduct(page) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/product/get-product?page=${page}`
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchProductById(productId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/product/get-product-by-id/${productId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchProductBySubCategoryId(subcategoryId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/product/get-product-by-subcategory-id/${subcategoryId}`
    );

    const data = response.data;
    resolve({ data });
  });
}
export function fetchProductByCategory(category) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/product/get-product-by-category",
      {
        categoryName: category,
      }
    );

    const data = response.data;
    resolve({ data });
  });
}
