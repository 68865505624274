import { configureStore } from "@reduxjs/toolkit";
import categoryReducers from "../features/Category/CategorySlice";
import subcategoryReducers from"../features/Subcategory/SubcategorySlice";
import productReducers from"../features/Product/ProductSlice";
import serviceReducers from "../features/Service/ServiceSlice";
import cartReducers from "../features/Cart/CartSlice";
import govermentReducers from "../features/Govermentschme/GovermentschmeSlice";
import contactUsReducers from "../features/ContactUs/ContactUsSlice";
import userReducers from "../features/User/UserSlice";
import phonepayReducers from "../features/PhonePay/PhonePaySlice";

export const store = configureStore({
  reducer: {
    category: categoryReducers,
    subcategory: subcategoryReducers,
    product: productReducers,
    service: serviceReducers,
    cart: cartReducers,
    govermentschme: govermentReducers,
    contactus: contactUsReducers,
     user: userReducers,
     phonepay: phonepayReducers,
  },
});
