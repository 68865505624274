import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReactImageMagnify from 'react-image-magnify';
import { FaShoppingCart } from "react-icons/fa";
import { AiFillThunderbolt } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProductByIdAsync,
  selectProduct,
  selectProductById,
} from "../features/Product/ProductSlice";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import ProductCarousel from "../component/Carousels/ProductCarousel";

const ProductDetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const product = useSelector(selectProductById);
  const realetedProduct = useSelector(selectProduct);
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductByIdAsync(id)).then((res) => {
      if (res.payload.status === 1) {
        setLoading(false);
      }
    });
  }, [id]);

  const [images, setImage] = useState(null);
  const handleImage = (imageurl) => {
    setImage(imageurl);
  };
  const isMobile = useBreakpointValue({ base: true, sm: false });
  useEffect(() => {
    setImage(product?.data[0]?.productImage[0]);
  }, [product]);

  const handlePrevious = () => {
    const newIndex =
      (startIndex - 1 + product?.data[0].productImage.length) %
      product?.data[0].productImage.length;
    setStartIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (startIndex + 1) % product?.data[0].productImage.length;
    setStartIndex(newIndex);
  };

  const visibleImages = [
    product?.data[0].productImage[startIndex],
    product?.data[0].productImage[
      (startIndex + 1) % product?.data[0].productImage.length
    ],
    product?.data[0].productImage[
      (startIndex + 2) % product?.data[0].productImage.length
    ],
  ];
  if (isMobile) {
    visibleImages.push(
      product?.data[0].productImage[
        (startIndex + 3) % product?.data[0].productImage.length
      ]
    );
  }

  return (
    <>
      {loading ? (
        <Flex justifyContent={"center"} m={"auto"} h={"400px"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#52771A"
            size="xl"
          />
        </Flex>
      ) : (
        <>
          <Box>
            <Flex
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              }}
              justifyContent="center"
            >
              <Box width={["100%", "50%"]}>
                {!isMobile && (
                  <ChevronUpIcon
                    w={"24px"}
                    h={"24px"}
                    ml="8%"
                    color="#52771A"
                    onClick={handlePrevious}
                  />
                )}

                <Box
                  mb={{ base: "20px", lg: "0" }}
                  // width={{ base: "100%", lg: "40%" }}
                  display="flex"
                  flexFlow={["column-reverse", "row"]}
                  border={"1px solid #D9D9D9"}
                  width={["100%", "700px"]}
                >
                  <Box>
                    {product?.status === 1 && (
                      <>
                        <Box
                          alignItems="center"
                          display={"flex"}
                          flexDirection={["row", "column"]}
                          gap={5}
                        >
                          {visibleImages.map((el, index) => (
                            <Box
                              key={index}
                              cursor="pointer"
                              border={"1px solid #D9D9D9"}
                              background="#FFF"
                              overflow="hidden"
                              width={"150px"}
                              height={["auto", "150px"]}
                              boxShadow="sm"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              _hover={{
                                boxShadow: "md",
                                border: "2px solid #52771A",
                              }}
                              onMouseEnter={() => handleImage(el)}
                            >
                              <Image
                                src={el}
                                alt=""
                                width="100%"
                                height={"100%"}
                                aspectRatio={3 / 2}
                                objectFit="contain"
                              />
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box
                    // borderWidth="1px"
                    width={"400px"}
                    height={["auto", "400px"]}
                    maxW="100%"
                    // mb="20px"
                    margin={"auto"}
                  >
                    {/* <Image
                      src={images}
                      alt=""
                      objectFit="contain"
                      width={["100%", "400px"]}
                      // height={["50%","400px"]}
                      aspectRatio={3 / 2}
                    /> */}
                    <ReactImageMagnify {...{
                      smallImage: {
        alt: 'product image',
        isFluidWidth: true,
        src: images,
        width:400
        
    },
    isHintEnabled:true,
    largeImage: {
        src: images,
        width: 700,
        height: 900,
    },
    enlargedImageContainerDimensions: {
      width: '100%',
      height: '100%',
  },
  hoverDelayInMs: 0,
                    hoverOffDelayInMs: 0,
                    enlargedImagePosition: 'over', 
}} />
                  </Box>
                </Box>
                {!isMobile && (
                  <ChevronDownIcon
                    w={"24px"}
                    h={"24px"}
                    ml="8%"
                    color="#52771A"
                    onClick={handleNext}
                  />
                )}
                <Stack
                  direction="row"
                  spacing="10px"
                  mt={"20px"}
                  ml={[0, "22%"]}
                  position={isMobile && "fixed"}
                  bottom={isMobile && 10}
                  w={isMobile && "100%"}
                  bg={isMobile && "white"}
                  zIndex={isMobile && 2}
                >
                  <Button
                    style={{
                      width: "260px",
                      height: "51px",
                      borderRadius: "7px",
                      background: "#52771A",
                      color: "#F3FFE1",
                      textAlign: "center",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // onClick={() => handleAdd(product)}
                    }}
                    leftIcon={<Icon as={FaShoppingCart} boxSize={6} />}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    style={{
                      width: "260px",
                      height: "51px",
                      borderRadius: "7px",
                      background: "#F6AA00",
                      color: "#F3FFE1",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center", // Center content vertically
                      justifyContent: "center", // Center content horizontally
                      // onClick={() => handleFavourite(product)}
                    }}
                    leftIcon={
                      <Icon
                        as={AiFillThunderbolt}
                        boxSize={6} // Adjust the icon size as needed
                        flexShrink={0}
                      />
                    }
                  >
                    BUY NOW
                  </Button>
                </Stack>
              </Box>

              <Box
                p={[5, 0]}
                width={["100%", "40%"]}
                textAlign={"justify"}
                height={["auto", "450px"]}
                mt="1.8%"
                display={"flex"}
                flexDirection="column"
                gap={"10px"}
                mb={["5%", "12%"]}
              >
                <Text
                  color="#000000"
                  textAlign="left"
                  fontSize={["20px", "24px"]}
                  fontWeight="500"
                >
                  {product?.data[0]?.productName}
                </Text>

                <Text
                  color="#000000"
                  textAlign="left"
                  fontSize={["24px", "32px"]}
                  fontWeight="500"
                >
                  ₹ {product?.data[0]?.productRate}
                </Text>
                <Divider color={"#D9D9D9"} />
                <Box display={"flex"}>
                  <Text color={"#8E8E8E"} fontSize="16px" fontWeight={"500"}>
                    SIZE
                  </Text>
                  <Text color={"#404040"} fontSize="16px" fontWeight={"500"}>
                    : 50m
                  </Text>
                </Box>
                <Text
                  fontSize={["20px", "24px"]}
                  fontWeight={"500"}
                  color="#000000"
                >
                  Product Details :
                </Text>

                <Box width={["100%", "594px"]}>
                  <Text
                    color="#8E8E8E"
                    fontWeight="500"
                    fontSize={["10px", "16px"]}
                  >
                    {product?.data[0]?.productDescription}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>

          <Box
            p={["10px", "20px"]}
            backgroundColor={"#FFF7E9"}
            height={["200px", "300px"]}
            mb={["40%", "18%"]}
          >
            <Heading
              mt={["0px", "30px"]}
              color={"#404040"}
              fontSize={["20px", "32px"]}
              fontWeight={"700"}
              ml="35px"
              mb={["10px", "20px"]}
            >
              RELATED PRODUCTS
            </Heading>

            {realetedProduct?.status === 1 && (
              <ProductCarousel data={realetedProduct?.data?.items} />
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default ProductDetailPage;
