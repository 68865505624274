import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const OrderCard = ({id,productName,imageSrc,size,qunatity,price,deliveryDate}) => {
  const isMobile = useBreakpointValue({base: true,lg:false})

  return (
    <Box display={"flex"} justifyContent="space-between" mb="2%">
      <Flex gap={"20px"}>
        <Box >
          <Image
            src={imageSrc}
            w={["80px","100px"]}
            h={["80px","100px"]}
            objectFit={"cover"}
          />
        </Box>
        <Box mt={"10px"} >
          <Text 
            color={"#404040"}
            fontFamily="Inter"
            fontSize={["10px","16px"]}
            fontWeight="500"
          >
            {productName}
          </Text>
          {!isMobile && (
            <>
          <Text
            color={"#8E8E8E"}
            fontFamily="Inter"
            fontSize={"13px"}
            fontWeight="500"
          >
            Size : {size} Qty : {qunatity}
          </Text>
          <Text
            color={"#000"}
            fontFamily="Inter"
            fontSize={["10px","16px"]}
            fontWeight="500"
          >
            {price}
          </Text>  </>)}
        </Box>
      </Flex>
{!isMobile && (
      <Box mt={"30px"}>
        <Text
          color={"#8E8E8E"}
          fontFamily="Inter"
          fontSize={"10px"}
          fontWeight="500"
        >
          Delivery Expected by
        </Text>
        <Text
          color={"#404040"}
          fontFamily="Inter"
          fontSize={["10px","13px"]}
          fontWeight="700"
        >
          {deliveryDate}
        </Text>
      </Box>)}
{isMobile && <ChevronRightIcon mt={"20px"}/>}
      
    </Box>
  );
};

export default OrderCard;
