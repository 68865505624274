import { Box, Button, Container, Divider, Heading, Text, Image, VStack, HStack, useBreakpointValue, Flex } from "@chakra-ui/react";
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
  } from '@chakra-ui/react'
import React from "react";

const OrderDetailPage = () => {

    const steps = [
        { title: 'Order Confirmed', description: 'Oct 14, 2023' },
        { title: 'Delivered', description: 'Oct 17, 2023' }
    ];
    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
      })
  const orderData = {
    id: 1,
    productName: "Rigid PVC Pipes",
    imageSrc: "https://5.imimg.com/data5/LQ/FK/MY-2072910/rigid-pvc-pipes-500x500.jpg",
    size: "50m",
    quantity: 1,
    price: "₹ 50,000",
    deliveryDate: "14 September 2023",
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
 
  
  return (
    <Box>
      <Container maxW="container.lg" bg="white" p={6} borderRadius="md" boxShadow="xl">
        <Box textAlign="center" mb={6}>
          <Heading as="h1" size="xl" color="teal.600">
            Order Details
          </Heading>
        </Box>
<Text>Order ID- 2302423740</Text>
      
          <Flex  justifyContent={"space-between"}>
            <Box>
            <Text fontSize={{base:"sm",lg:"2xl"}} >
                {orderData.productName}
              </Text>
              <Text fontSize={{base:"sm",lg:"2xl"}}>
                {orderData.price}
              </Text>
            </Box>
              <Image 
              src={orderData.imageSrc} 
              w={isMobile ? "100px" : "150px"} 
              h={isMobile ? "100px" : "150px"} 
              objectFit="cover" 
              borderRadius="md" 
              alt={orderData.productName} 
              />
              </Flex>
              <Stepper size="sm" index={activeStep} orientation="vertical" height="120px" colorScheme="green">
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink="0">
                                <StepTitle fontSize={{ base: "sm", lg: "md" }} fontWeight="bold">{step.title}</StepTitle>
                                <StepDescription fontSize={{ base: "xs", lg: "sm" }} color="gray.500">{step.description}</StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>

          
         

      </Container>
    </Box>
  );
};

export default OrderDetailPage;
