import { Box, Button, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addProductQuantityFromCartAsync, createCartAsync, selectallreadyAddedCart } from "../features/Cart/CartSlice";
import { useEffect } from "react";

const ExploreProductCard = ({ user_id, id, name, price, image,quan }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(quan);
 const alreadyToCart = useSelector(selectallreadyAddedCart);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity) {
      setQuantity(quantity - 1);
    } 
  };
  const handleCartItems = () => {
    let product = {
      product_id: id,
      productName: name,
      price: price,
      image: image,
      quantity: 1,
    };
        
        dispatch(createCartAsync({ user_id, product }));
      
  };
     
  useEffect(() => {
      setQuantity(alreadyToCart[id]);
  }, [id,alreadyToCart]);

useEffect(() => {
  if(quantity === 0){
    dispatch(
      addProductQuantityFromCartAsync({ user_id, product_id: id, quantity })
    );
  }
  else if(quantity>alreadyToCart[id] || quantity < alreadyToCart[id] ){
 let timer;
 const dispatchActionAfterDelay = () => {
   dispatch(
     addProductQuantityFromCartAsync({ user_id, product_id: id, quantity })
   );
 };

 clearTimeout(timer);
 timer = setTimeout(dispatchActionAfterDelay,2000);
 return () => {
   clearTimeout(timer);
 };
  }
 
}, [dispatch,quantity,alreadyToCart,id,user_id]);

  return (
    <Box border={"1px solid #FFF"}
      w={["180px","300px"]} 
      h="auto"
      borderRadius="14px"
      backgroundColor="#FFF"
      boxShadow="0px 4px 25px 0px rgba(0, 0, 0, 0.10)"
      margin={"auto"}
    >
      <Link to={`/productdetailpage/${id}`}>
        <Box>
          <Box  w={["100px","191px"]} h={["100px","191px"]} mx="auto" mt="30px" overflow="hidden">
            <Image
              src={image}
              w={"100%"}
              h={"100%"}
              objectFit="cover"
              m={"auto"}
              alt="Product"
            />
          </Box>

          <Box width={"80%"} margin="auto">
            <Text
              color="#000"
              textAlign="center"
              fontFamily="Inter"
              fontSize={["10px","16px"]}
              fontWeight="400"
              lineHeight="normal"
              letterSpacing="0.48px"
              mt="20px"
              whiteSpace="nowrap" 
              overflow="hidden" 
              textOverflow="ellipsis" 
              maxWidth="100%" 
            >
              {name}
            </Text>
            {/* <Text
              color="#000"
              fontFamily="Inter"
              fontSize={["10px","14px"]}
              fontWeight="700"
              lineHeight="normal"
              letterSpacing="0.42px"
              ml={"10px"}
              mt="20px"
            >
              ₹{price}
            </Text>
            <Text as='del'>₹{price}</Text>
            <Text as='b'>Discount: ₹{price}</Text> */}
          </Box>
          <Box width="80%" margin="auto">
  {/* <Text
    color="#000"
    textAlign="center"
    fontFamily="Inter"
    fontSize={["10px", "14px"]}
    fontWeight="700"
    lineHeight="normal"
    letterSpacing="0.42px"
    mt="20px"
  >
    ₹{price}
  </Text> */}
  <Box textAlign="center" mt="8px">
    <Text
      as="span"
      display="inline-block"
      mr="5px"
      fontSize="16px"
      fontWeight="700"
    >
      ₹{price}
    </Text>
    <Text
      as="del"
      color="#888"
      fontSize="16px"
      ml={2}
    >
      ₹{(price * 0.8).toFixed(2)} {/* Assuming 20% discount */}
    </Text>
  </Box>
  <Text
    color="#52771A"
    textAlign="center"
    fontSize="14px"
    mt="8px"
     fontWeight={700}
  >
   54% off
  </Text>
</Box>

        </Box>
      </Link>
      {alreadyToCart[id] ? (
        <Box mt="20px" textAlign="center" mb={"20px"}>
          <Button
            variant="outline"
            borderRadius="full"
            fontSize="20px"
            minWidth="40px"
            minHeight="40px"
            p="0"
            onClick={handleDecrement}
          >
            -
          </Button>
          <Text
            display="inline-block"
            fontSize="20px"
            fontWeight="500"
            mx="10px"
          >
            {quantity}
          </Text>
          <Button
            variant="outline"
            borderRadius="full"
            fontSize="20px"
            minWidth="40px"
            minHeight="40px"
            p="0"
            onClick={handleIncrement}
          >
            +
          </Button>
        </Box>
      ) : (
        <Button
          width={"80%"}
          borderRadius="7px"
          backgroundColor="#52771A"
          color="#F3FFE1"
          textAlign="center"
          fontFamily="Inter"
          fontSize={["10px","16px"]}
          fontWeight="500"
          lineHeight="normal"
          mt="20px"
          mx="auto"
          mb={"20px"}
          display="block"
          _hover={{ bg: "#405a13" }}
          onClick={handleCartItems}
        >
          ADD TO CART
        </Button>
      )}
    </Box>
  );
};

export default ExploreProductCard;
