import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Button } from '@chakra-ui/react';

const PaymentPopup = ({ isOpen, onClose, paymentUrl }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <iframe src={paymentUrl} width="100%" height="900px" frameBorder="0" title="Payment Gateway"></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentPopup;
