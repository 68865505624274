import { Avatar, Box, Button, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import logo from "../../images/logo.jpg";
import carticon from "../../images/cart icon.png";
import searchicon from "../../images/search icon.png";
import "./Navbar.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import {
  fetchUserAsync,
  loginUserSelector,
} from "../../features/User/UserSlice";
import { allreadyAddedCartAsync, createCartAsync, fetchUserCartAsync, selectCart } from "../../features/Cart/CartSlice";
import SideNavbar from "./SideNavbar";
const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [cartItem, setCartItem] = useState(0);
  const loginUser = useSelector(loginUserSelector);
  const countCartItem = useSelector(selectCart);
  useEffect(() => {
    dispatch(fetchUserCartAsync(loginUser?.data?.user_id))
    if (loginUser?.status === 1) {
      dispatch(fetchUserAsync({ id: loginUser.data.user_id }));
      const user_id  = loginUser?.data?.user_id;
      const productData = JSON.parse(localStorage.getItem("productData")) || { data: { products: [] } };
       const product = productData?.data?.products;
      dispatch(createCartAsync({user_id,product}));
      localStorage.removeItem("productData");
    } 
  }, [dispatch, loginUser]);
  useEffect(() => {
    setCartItem(countCartItem?.data?.products?.length);
    let data = {};
         countCartItem?.data?.products?.forEach((el) => {
           data[el.product_id] = el.quantity;
         });
         dispatch(allreadyAddedCartAsync(data));
  }, [dispatch,countCartItem]);
  return (
    <Box h={["50px","100px"]}
      style={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Box w={"50px"}
        display={["block", "block", "block", "block", "none"]}
        margin="auto"
      >
        <SideNavbar />
      </Box>
      <Box display={"flex"} w="100%" justifyContent={["space-between","space-between","space-between","space-between","space-around"]}>
        <Link to={"/"}>
          <Box display={"flex"} alignItems={"center"}  gap={["0px","20px"]} ml={["0px","40px"]} mt={["5px","10px"]}>
            <Image 
              src={logo}
              alt="logo"
              width={["40px", "50px", "55px"]}
              height={["40px", "68px", "78px"]}
            ></Image>
            <Text 
              color="#52771a"
              fontFamily="Krona One"
              fontWeight={"400"}
              fontSize={["10px", "16px", "24px"]}
           
            >
              SONAM
            </Text>
          </Box>
        </Link>

        {/* Logo and Login Button for large screens */}
        <Box
          display={["none", "none", "none", "none", "flex"]}
          id="options_box"
        >
          <NavLink to={"/products"}>
            <Button className="btn" variant={"ghost"} bg={location.pathname === "/products"?"#F3FFE1":"none"}>
              PRODUCTS
            </Button>
          </NavLink>
          <Link to={"/service"}>
            <Button className="btn" variant={"ghost"} bg={location.pathname === "/service"?"#F3FFE1":"none"}>
              SERVICES
            </Button>
          </Link>
          <Link to={"/about"}>
            <Button className="btn" variant={"ghost"} bg={location.pathname === "/about"?"#F3FFE1":"none"}>
              ABOUT US
            </Button>
          </Link>
          <Link to={"/govtscheme"}>
            <Button className="btn" variant={"ghost"} bg={location.pathname === "/govtscheme"?"#F3FFE1":"none"}>
              GOVT SCHEMES
            </Button>
          </Link>
          <Link to={"/contact"}>
            <Button className="btn" variant={"ghost"} bg={location.pathname === "/contact"?"#F3FFE1":"none"}>
              CONTACT US
            </Button>
          </Link>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={["20px","40px"]} >
          <Box
           display={"flex"} justifyContent={"center"} alignItems={"center"}  gap={["20px","40px"]}
          >
            <Link to={"/products"}>
             <Image
              src={searchicon}
              alt="logo"
              width={["20px","24px"]}
              height={["20px","24px"]}
            />  </Link>
            <Link to="/cart">
              <Box style={{ position: "relative" }}>
                {cartItem > 0 && (
                  <Text
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red"
                    color="white"
                    borderRadius="25%"
                    width={["15px","20px"]}
                    height={["15px","20px"]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize={["0.5rem","1rem"]}
                    fontWeight="500"
                    border="1px solid red"
                  >
                    {cartItem}
                  </Text>
                )}

                <Image
                  src={carticon}
                  alt="Cart Icon"
                  width={["20px","24px"]}
                  height={["20px","24px"]}
                />
              </Box>
            </Link>

           
          </Box>
          {loginUser?.status === 1 ? (
            <Link to={"/myaccount"}>
              <Avatar bg="#52771A" w={["18px","24px"]} h={["18px","24px"]} mx={3} mt={1}/>
            </Link>
          ) : (
            <LoginModal />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
