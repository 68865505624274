import { Box, Button, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import ExploreProductCard from "../../component/ExploreProductCard";
import Pagination from "../../component/Pagination";
import CategoryNameCard from "./CategoryNameCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryAsync, selectCategory } from "../../features/Category/CategorySlice";
import {
  fetchProductAsync,
  selectProduct,
} from "../../features/Product/ProductSlice";
import ProductPageImage from "../../images/ProductPageImage.png";
import { loginUserSelector } from "../../features/User/UserSlice";
import { useEffect } from "react";
import ExploreProductCardSkeleton from "../../component/Skeletons/ExploreProductCardSkeleton";
import CategoryNameCardSkeleton from "../../component/Skeletons/CategoryNameCardSkeleton";

const Home = () => {
  const dispatch = useDispatch();
  const loginUser = useSelector(loginUserSelector);
  const category = useSelector(selectCategory);
  const product = useSelector(selectProduct);
  const [CategoryLoading, setCategoryLoading] = useState(true);
  const [ProductLoading, setProductLoading] = useState(true);
   const handleLoadMore = () =>{
    setProductLoading(true);
    let basicpage = localStorage.getItem("basicpage");
      basicpage = +basicpage || 2;
    dispatch(fetchProductAsync(basicpage)).then((res) => {
      if (res.payload.status === 1) {
        localStorage.setItem("basicpage", basicpage + 1);
        setProductLoading(false);
      }
    });
   }
   useEffect(() =>{
    if(product?.data?.items.length > 0 ){
     setProductLoading(false);
    }
   
   },[product])
  
  useEffect(() => {
    if(category.length === 0){
      dispatch(fetchCategoryAsync()).then((res) => {
        if (res.payload.status === 1) {
          setCategoryLoading(false);
        }
      });
    }else{
      setCategoryLoading(false)
    }
  }, [dispatch,category]);



  let totalPages = Math.ceil(
    product?.data?.totalCount / product?.data?.itemsPerPage
  );
  return (
    <Box>
      <Box
        width={"100%"}
        height={["auto", "auto", "auto", "260px", "260px"]}
        backgroundColor={"#E2E9FB"}
        display="flex"
        justifyContent={"space-around"}
      >
        <Box w={["150px", "475px"]}>
          <Image src={ProductPageImage} />
        </Box>
        <Box mt={["8%", "5%"]}>
          <Text
            color={"#404040"}
            fontSize={["10px", "20px"]}
            fontWeight={500}
            textAlign={"right"}
          >
            Farming Essentials for the
          </Text>
          <Text
            color={"#208674"}
            fontFamily="Kaushan Script"
            fontSize={["20px", "54px", "54px", "64px", "64px"]}
            fontWeight={400}
          >
            Modern Grower
          </Text>
        </Box>
      </Box>
      <Box width={"100%"} height="auto" pb="5%" backgroundColor={"#F3FFE1"}>
        <Box paddingTop={["10px", "30px"]}>
          <Text
            fontSize={["20px", "32px"]}
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Shop by Category
          </Text>
        </Box>

        <Box
          mt={["20px", "30px"]}
          style={{
            display: "grid",
          }}
          gridTemplateColumns={[
            "repeat(3,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(4,1fr)",
            "repeat(4,1fr)",
          ]}
          p={2}
          gap={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }}
        >
          {CategoryLoading ? (
            <CategoryNameCardSkeleton />
          ) : (
            category.status === 1 &&
            category?.data?.map((el) => (
              <CategoryNameCard
                key={el._id}
                category_name={el.categoryName}
                image={el.categoryImage}
                id={el._id}
              />
            ))
          )}
        </Box>
      </Box>
      <Box>
        <Box mt={"20px"} mb="20px" ml="40px">
          <Text
            fontSize={["20px", "32px"]}
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
            }}
          >
            Explore Products
          </Text>
        </Box>

        <Box
          gap={["10px", "20px"]}
          style={{
            display: "grid",
            justifyContent: "center",
          }}
          gridTemplateColumns={[
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(4,1fr)",
          ]}
        >
          {ProductLoading ? (<ExploreProductCardSkeleton/>):(
          product?.status === 1 &&product?.data?.items.length > 0 &&
            product?.data?.items?.map((el) => (
              <ExploreProductCard
                key={el._id}
                user_id={loginUser?.data?.user_id}
                id={el._id}
                name={el.productName}
                price={el.productRate}
                image={el.productImage[0]}
                quan={el.quantity}
              />
            )) )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt="20px" mb="40px">
      <Button
  onClick={handleLoadMore}
  width="200px"
  bg="#52771A"
  color="white"
  border="1px solid var(--ltn__secondary-color)"
  fontWeight="600"
  _hover={{ bg: "var(--ltn__secondary-color-hover)" }} // Optionally add hover effect
>
  Load More
</Button>
      </Box>
    </Box>
  );
};

export default Home;
