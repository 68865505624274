import React from "react";
import {
  Box,
  Text,
  Radio,
  InputGroup,
  IconButton,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { AddAddressForm } from "../Form/AddAddressForm";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDeliveryAddressAsync,
  selectedAddresSelector,
  updateSelectedAddressAsync,
} from "../../features/User/UserSlice";

const SelectAddress = ({ data, user_id }) => {
  const { fullName, address, mobileNo, _id, city, landmark, pincode, state } =
    data;
const toast = useToast();
  const dispatch = useDispatch();

  const selectedAddress = useSelector(selectedAddresSelector);

  const handleChange = (id) => {
    dispatch(updateSelectedAddressAsync({ user_id, address_id: id }));
  };

  const handleDeleteDeliveryAddress = (user_id, address_id) => {
    console.log(user_id,address_id,">...both")
    dispatch(deleteDeliveryAddressAsync({ user_id, address_id })).then(
      (res) => {
        toast({
          title: "Deleted Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    )
  };

  return (
    <Box w={["100%","400px","500px","500px","350px"]}
      borderWidth="1px"
      borderRadius="lg"
      p="4"
      mb="4"
      borderColor={
        _id === selectedAddress?.data?.address_id ? "#52771A" : "gray.200"
      }
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
    >
      <Flex alignItems={"center"}>
        <Radio
          size="lg"
          isChecked={selectedAddress?.data?.address_id === _id}
          onClick={() => handleChange(_id)}
          value={_id}
          colorScheme="green"
          mr={4}
        />
        <Box flex="1" overflow="hidden">
          <Text fontWeight="bold">{fullName}</Text>
          <Text noOfLines={2}>{address}</Text>
          <Text>{landmark}</Text>
          <Text>
            {city}, {state} - {pincode}
          </Text>
          <Text>Phone: +91-{mobileNo}</Text>
        </Box>
       
      </Flex>
      <Box display={"flex"} justifyContent={"flex-end"}>
          <InputGroup w={"40px"}
            borderRadius={"7px"}
          >
            <AddAddressForm
              user_id={user_id}
              address_id={_id}
              addressData={data}
            />
          </InputGroup>

          <IconButton 
            icon={<DeleteIcon />}
            size={"sm"}
            colorScheme="red"
            onClick={() => handleDeleteDeliveryAddress(user_id, _id)}
          />
        </Box>
    </Box>
  );
};

export default SelectAddress;
