// A mock function to mimic making an async request for data
import axios from "axios";
export function createContactus(contactus) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/contactus/send-email",
      contactus
    );
    const data = response.data;

    resolve({ data });
  });
}
