import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Input, InputGroup, InputLeftElement, useBreakpointValue } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExploreProductCard from "../component/ExploreProductCard";
import ProductPageSkeleton from "../component/Skeletons/ProductPageSkeleton";
import { debounce } from "lodash";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductAsync, selectProduct } from "../features/Product/ProductSlice";
import { loginUserSelector } from "../features/User/UserSlice";

const Products = () => {
  const dispatch = useDispatch();
  const loginUser = useSelector(loginUserSelector);
  const product = useSelector(selectProduct);
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductLoading, setProductLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const navigate = useNavigate();
  const fetchData = async (searchQuery) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://dummyjson.com/products/search?q=${searchQuery}&page=1&limit=10`);
      setResults(response?.data?.products);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const debouncedFetchData = useCallback(
    debounce((searchQuery) => fetchData(searchQuery), 300),
    []
  );
  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      debouncedFetchData(value);
    } else {
      setResults([]);
    }
  };
  // const handlePageChange = (newPage) => {
  //   if (newPage >= 1 && newPage <= totalPages) {
  //     setCurrentPage(newPage);
  //   }
  // };
  useEffect(() => {
    dispatch(fetchProductAsync(currentPage)).then((res) => {
      if (res.payload.status === 1) {
        setProductLoading(false);
      }
    });
  }, [dispatch, currentPage]);

  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
      <HStack  spacing={["2px", "30px"]} ml={[2, 10]} pt={5}>
        <Button
          leftIcon={<ArrowBackIcon w={8} h={8} color={"#52771A"} />}
          color="#52771A"
          variant="none"
          onClick={() => navigate(-1)}
        >
          {isMobile ? "" : "Go back"}
        </Button>
        <InputGroup>
          <InputLeftElement children={<SearchIcon />} />
          <Input
            placeholder="Search your Product..."
            value={query}
            onChange={handleInputChange}
            bgColor="gray.100"
            borderRadius={"50px"}
            fontSize={"lg"}
            focusBorderColor="#52771A"
            _placeholder={{ color: "gray.400" }}
            w={["90%", "80%"]}
          />
        </InputGroup>
      </HStack>
      {loading && <ProductPageSkeleton />}

      <Box
        mt={"30px"}
        style={{
          display: "grid",
          justifyContent: "center",
        }}
        gridTemplateColumns={["repeat(2,1fr)", "repeat(2,1fr)", "repeat(2,1fr)", "repeat(3,1fr)", "repeat(4,1fr)"]}
      >
        {results.map((el) => (
          <ExploreProductCard name={el.title} />
        ))}
      
      </Box>
      <Box
          gap={["10px", "20px"]}
          style={{
            display: "grid",
            justifyContent: "center",
          }}
          gridTemplateColumns={["repeat(2,1fr)", "repeat(2,1fr)", "repeat(2,1fr)", "repeat(3,1fr)", "repeat(4,1fr)"]}
        >
          {ProductLoading ? (
            <ProductPageSkeleton />
          ) : (
            product?.status === 1 &&
            product?.data?.items?.map((el) => (
              <ExploreProductCard
                key={el._id}
                user_id={loginUser?.data?.user_id}
                id={el._id}
                name={el.productName}
                price={el.productRate}
                image={el.productImage[0]}
                quan={el.quantity}
              />
            ))
          )}
        </Box>
    </Box>
  );
};

export default Products;
