import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPayment } from "./PhonePayAPI";

const initialState = {
  value: 0,
  status: "idle",
  payment: null,
};



export const createPaymentAsync = createAsyncThunk(
  "phonepay/createPayment",
  async (PaymentData) => {
    const response = await createPayment(PaymentData);

    return response.data;
  }
);



export const ContactUsSlice = createSlice({
  name: "phonepay",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createPaymentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPaymentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.payment = action.payload;
      });
  },
});



export const selectPayment = (state) => state.phonepay.payment; 


export default ContactUsSlice.reducer;
