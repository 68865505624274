// A mock function to mimic making an async request for data
import axios from "axios";
export function createPayment(paymentData) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/phonepay/payment",
      {
        name: paymentData.name ,
        phone: paymentData.phone,
        price: paymentData.price,
        user_id:paymentData.user_id
      }
    );
    const data = response.data;

    resolve({ data });
  });
}
