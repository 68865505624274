import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import "./Footer.css";
import emailicon from "../../images/dashicons_email.png";
import facebookicon from "../../images/ic_baseline-facebook.png";
import twittericon from "../../images/mdi_twitter.png";
import phoneicon from "../../images/ph_phone-fill.png";
import instagramicon from "../../images/ri_instagram-fill.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import SocialLinksRedirections from "../../shared/SocialLinksRedirections";
import {mailtoLink,handlePhoneClick } from "../../utils";
const Footer = () => {
 
  const facebookUrl = 'https://www.facebook.com/your-page';
  const instagramUrl = 'https://www.instagram.com/your-page';
  const twitterUrl = 'https://www.twitter.com/your-page';

  const googleTranslateConfig = {
    pageLanguage: "en",
    autoDisplay: false,
    includedLanguages: "en,hi,bn,kn",
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      googleTranslateConfig,
      "google_translate_element"
    );
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    document.body.appendChild(script);

    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
      {/* <div id="google_translate_element"></div> */}
    <Box display={"flex"} justifyContent={"space-evenly"}  gap={["20px","40px","40px","40px","0px"]}  alignItems={["center","center","center","center","flex-start"]} flexDirection={["column","column","column","column","row"]} textAlign={["center","center","center","center","left"]} id="main_box_footer" pt={["10%","5%"]}>
     
        <Box id="first_box" w={["80%","80%","80%","80%","30%"]}  >
          <Box mb={["10px","20px","20px","20px","85px"]}>
            <Text className="heading_name" fontSize={["20px","24px"]}>Sonam Agrotech pvt ltd.</Text>
            <Text id="lorem_text" fontSize={["10px","16px"]}>
              Lorem ipsum dolor sit amet, consectetur. Proin convallis mauris ut
              lectus dictum, sit amet ultricies sapien convallis.{" "}
            </Text>
          </Box>
          <Box>
            <Text className="normal_text" style={{ color: "#FFF" }} fontSize={["10px","16px"]}>
              @2023 Sonam Agrotech Pvt Ltd.
            </Text>
          </Box>
        </Box>
        <Box id="second_box"  >
          <Text className="heading_name" fontSize={["20px","24px"]}>Sonam Agrotech</Text>
         <Link to={"/"}> <Text className="normal_text" fontSize={["10px","16px"]}>Home</Text></Link>
         <Link to={"/products"}><Text className="normal_text" fontSize={["10px","16px"]}>Products</Text></Link> 
         <Link to={"/about"}><Text className="normal_text" fontSize={["10px","16px"]}>About Us</Text></Link>
         <Link to={"/service"}><Text className="normal_text" fontSize={["10px","16px"]}>Services</Text></Link> 
         <Link to={"/govtscheme"}><Text className="normal_text" fontSize={["10px","16px"]}>Govt Scheme</Text></Link> 
         <Link to={"/contact"}><Text className="normal_text" fontSize={["10px","16px"]}>Contact Us</Text></Link> 
        </Box>
        <Box id="third_box"  >
          <Text className="heading_name" fontSize={["20px","24px"]}>Get in touch</Text>
          <a href="#" onClick={handlePhoneClick}> <Box className="icon_or_text" justifyContent={["center","center","center","center","left"]}>
            <Image src={phoneicon} alt="phone" />
            <Text className="normal_text" fontSize={["10px","16px"]}>9142429858</Text>
          </Box></a>
          <a href={mailtoLink}>
          <Box className="icon_or_text" justifyContent={["center","center","center","center","left"]}>
            <Image src={emailicon} alt="email" />
            <Text className="normal_text" fontSize={["10px","16px"]}>info@sonamagrotech.com</Text>
          </Box></a>
          <SocialLinksRedirections iconSrc={facebookicon} altText="Facebook" socialUrl={facebookUrl} />
      <SocialLinksRedirections iconSrc={instagramicon} altText="Instagram" socialUrl={instagramUrl} />
      <SocialLinksRedirections iconSrc={twittericon} altText="Twitter" socialUrl={twitterUrl} />
        </Box>
        <Box id="fourth_box"  >
          <Text className="heading_name" fontSize={["20px","24px"]}>Our Policies</Text>
          <Link to={"/privacypolicy"}> <Text className="normal_text" fontSize={["10px","16px"]}>Privacy Policy</Text></Link>
         <Link to={"/returnpolicy"}><Text className="normal_text" fontSize={["10px","16px"]}>Return Policy</Text></Link> 
         <Link to={"/faq"}><Text className="normal_text" fontSize={["10px","16px"]}>FAQ</Text></Link>
         <Link to={"/termsofuse"}><Text className="normal_text" fontSize={["10px","16px"]}>Terms of  Use</Text></Link> 
         <Link to={"/shippingpolicy"}><Text className="normal_text" fontSize={["10px","16px"]}>Shipping / Delivery</Text></Link> 
         <Link to={"/cancelpolicy"}><Text className="normal_text" fontSize={["10px","16px"]}>Cancellation Policy</Text></Link> 

        </Box>
   
    </Box>
    </>
  );
};

export default Footer;
