import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import VisionImage from "../images/VisionAboutPage.png";
import MissionIcon from "../images/MissionIcon.png";
import pallavi from "../images/PallaviSuit.png";
import image2 from "../images/image2.png";

const About = () => {
  return (
    <>
      <Box
        padding={["10px", "40px"]}
        background="linear-gradient(180deg, #F3FFE1 0%, #FBFFF6 100%)"
        textAlign={"justify"}
      >
        <Box
          display={"grid"}
          gridTemplateColumns={[
            "1fr",
            "1fr",
            "1fr",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}
          gap="40px"
        >
          <Box>
            <Text
              color={"#404040"}
              fontFamily="Inter"
              fontSize={["20px", "32px", "42px", "52px", "52px"]}
              fontWeight="700"
            >
              About Our Company{" "}
            </Text>
            <Text
              fontSize={["20px", "32px", "42px", "52px", "52px"]}
              color="#52771A"
              fontFamily="Inter"
              // fontSize={"52px"}
              fontWeight="700"
            >
              Sonam
            </Text>

            <Text
              color="#000"
              fontFamily="Inter"
              fontSize={["10px", "24px"]}
              fontWeight="400"
              width={["auto", "auto", "auto", "598px", "598px"]}
              mt={["10px", "33px"]}
            >
              We’re here to revolutionize the farming industry using the modern
              technology and tools.
            </Text>
          </Box>
          <Box
            width={"100%"}
            height={["200px", "366px"]}
            backgroundColor={"black"}
          >
            <Image src="" />
          </Box>
        </Box>
        <Box
          backgroundColor={"black"}
          width="100%"
          height={["200px", "368px"]}
          marginTop={["10px", "30px"]}
        >
          <Image src="" />
        </Box>
        <Box mt={"25px"}>
          <Text fontSize={["10px", "16px"]}>
          Founded in 2021, Sonam Agrotech Private Limited has rapidly emerged as a key player in the agricultural sector, specializing in the trading of fertilizers and agrochemical products. With its registered office located in Bihar, India, the company is steadfast in its commitment to delivering high-quality agricultural solutions to farmers, ensuring the enhancement of productivity and sustainability in farming practices.
Sonam Agrotech stands out for its comprehensive range of products and services designed to meet the diverse needs of farmers. The company's core offerings include:

 Sonam Agrotech provides a wide array of fertilizers and agrochemical products that are essential for soil health and crop protection. These products are meticulously sourced and tested to ensure they meet the highest standards of quality and efficacy.

 Understanding that soil health is paramount to successful farming, Sonam Agrotech offers specialized soil fertilization services. These services are tailored to the specific needs of the soil, ensuring optimal nutrient balance and enhancing soil fertility.

Beyond providing products, Sonam Agrotech offers a suite of services aimed at improving agricultural practices. This includes guidance on best farming practices, crop management, and pest control, helping farmers achieve better yields and improved crop quality.

Sonam Agrotech is also dedicated to ensuring that farmers benefit from various government schemes aimed at agricultural development. The company actively assists farmers in understanding and accessing these schemes, which can provide financial support, subsidies, and technical assistance. By bridging the gap between government initiatives and farmers, Sonam Agrotech plays a crucial role in enhancing the economic well-being of the agricultural community.

At the heart of Sonam Agrotech's operations is a commitment to quality and sustainability. The company employs rigorous quality control measures to ensure that all products meet stringent standards. Additionally, Sonam Agrotech promotes sustainable farming practices that protect the environment while maximizing productivity. This dual focus on quality and sustainability ensures long-term benefits for farmers and the broader ecosystem.

Sonam Agrotech Private Limited is more than just a provider of agricultural products; it is a partner in progress for farmers. By offering high-quality fertilizers and agrochemicals, specialized soil fertilization services, and support for government schemes, Sonam Agrotech is dedicated to empowering farmers and enhancing agricultural productivity. As the company continues to grow, it remains committed to its mission of fostering sustainable and profitable farming practices, ensuring a brighter future for the agricultural community in Bihar and beyond.
          

          </Text>
        </Box>
        <Box
          p={"20px"}
          display={"flex"}
          flexDirection={["column", "column", "column", "row", "row"]}
          justifyContent="space-around"
          mt={"25px"}
          width={"100%"}
          height={["auto", "auto", "auto", "auto", "435px"]}
          borderRadius={"24px"}
          backgroundColor="#ECFFCF"
          boxShadow={" 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
        >
          <Box>
            <Text
              color={"#404040"}
              fontFamily="Inter"
              fontSize={["20px", "36px"]}
              fontWeight="700"
            >
              Vision
            </Text>
            <Image
              src={VisionImage}
              w={["100%", "336px"]}
              h={["200px", "336px"]}
            />
          </Box>
          <Box w={["auto", "auto", "auto", "auto", "830px"]}>
            <Text
              color={"#000000"}
              fontSize={["10px", "16px"]}
              fontWeight={"400"}
            >
              Loreum Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).l
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </Text>
          </Box>
        </Box>

        <Box mt={"30px"}>
          <Text
            color={"#404040"}
            fontSize={["20px", "36px"]}
            fontWeight={"700"}
            textAlign="center"
          >
            Mission
          </Text>
          <Text
            color={"#000000"}
            textAlign="center"
            fontSize={["10px", "16px"]}
            fontWeight="400"
          >
            This is a sample text for mission
          </Text>
          <Box
            display="grid"
            gridTemplateColumns={[
              "1fr",
              "1fr",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
            ]}
            gap="12px"
            mt={"20px"}
          >
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(3, 54, 255, 0.35)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 1
              </Text>
              <Box
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
                p={2}
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(255, 100, 26, 0.31)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              {" "}
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 2
              </Text>
              <Box
                p={2}
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
              gap={"10px"}
              h="auto"
              borderRadius={"24px"}
              backgroundColor="rgba(78, 203, 113, 0.35)"
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            >
              {" "}
              <Image src={MissionIcon} mt="10px" w={["15%", "auto"]} />
              <Text
                color={"#404040"}
                fontSize={["16px", "28px"]}
                fontWeight={"700"}
              >
                Mission 3
              </Text>
              <Box
                p={2}
                w={["auto", "auto", "auto", "auto", "385px"]}
                mb="10px"
              >
                <Text
                  color={"#000000"}
                  fontSize={["10px", "16px"]}
                  fontWeight={"400"}
                >
                  em Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of{" "}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        w={"100%"}
        h={["auto", "auto", "auto", "auto", "830px"]}
        background="#404040"
      >
        <Box ml={"6%"} mt={["10px","30px"]} pt={["10px","40px"]}>
          <Text color={"#FFFFFF"} fontSize={["20px","36px"]} fontWeight={"700"}>
            Meet our
          </Text>
          <Text color={"#FFFFFF"} fontSize={["30px","48px"]} fontWeight={"700"}>
            Founders
          </Text>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={[
            "repeat(2,1fr)",
            "auto",
            "auto",
            "auto",
            "repeat(2,629px)",
          ]}
          justifyContent="space-around" 
          p={["10px","40px"]} 
        >
          <Box  backgroundColor={"#D9D9D9"} >
            <Box>
            <Image 
              src={image2}
              w={"auto"}
              m={"auto"}
              // w={["100%","629px"]}
              h={["50%", "419px"]}
            /></Box>
            <Box p={["10px","20px"]} >
              <Text color={"#000000"} fontSize={["20px","32px"]} fontWeight={"700"}>
                Ganesh
              </Text>
              <Text color={"#000000"} fontSize={["10px","22px"]} fontWeight={"700"}>
                CEO
              </Text>
            </Box>
          </Box>
          <Box backgroundColor={"#D9D9D9"}>
            <Box>
            <Image src={pallavi} m={"auto"} w="auto" h={["50%","419px"]} />
            </Box>
            <Box p={["10px","20px"]}>
              {" "}
              <Text color={"#000000"} fontSize={["20px","32px"]} fontWeight={"700"}>
                Ramesh
              </Text>
              <Text color={"#000000"} fontSize={["10px","22px"]} fontWeight={"700"}>
                CTO
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default About;
