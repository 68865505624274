import React from 'react';
import { Box, Container, Heading, Text, ListItem, List, Link, OrderedList, Image } from '@chakra-ui/react';
import ShippingBanner from "../images/ShippingPolicy.jpg";
import { emailAddress,mailtoLink,handlePhoneClick } from '../utils';
const ShippingPolicy = () => {
 
  return (
    <Box bg={"linear-gradient(180deg, #f3ffe1 0%, #fff 100%)"}>
    <Container maxW="container.md" py={8} >
    <Box mb={6}>
        <Image
          src={ShippingBanner}
          alt="Terms of Use Banner"
          borderRadius="md"
          objectFit="content"
          width="100%"
          height={["100px","250px"]}
        />
      </Box>
      <Box bg="white" p={6} boxShadow="md" borderRadius="md">
        <Heading as={"h1"} mb={4} textAlign="center">
          Shipping & Delivery Policy
        </Heading>
        
        <OrderedList spacing={4} mt={4}>
          <ListItem>
            <Text fontWeight="bold">Shipping Services:</Text>
            <Text mt={2}>
              To ensure that your order reaches you in our standard time (6 to 12 working days) and in good condition, we will ship through standard courier agencies like Delhivery, FedEx, Xpressbees, Gati, Dotzot, Ecom Express and many more. <br />
              <Text as="i">Note: If we ship through postal service then it may take more time. Transport or bulk order takes 48 to 72 hrs to be dispatched.</Text>
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold">New User Confirmation:</Text>
            <Text mt={2}>
              If you are a new user, our executive will contact you to confirm your order (For perfect delivery our executive will confirm your exact delivery address and pin code). If your Pincode is not serviceable or we are not able to send the material, you will need to provide another address and pin code.
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold">Product Condition on Delivery:</Text>
            <Text mt={2}>
              If you believe that the product is not in good condition, or if the packaging is tampered with or damaged before accepting delivery, please refuse to take delivery of the package. Click some pictures of the package, write an instruction to the courier boy (e.g., "The package is tampered or damaged in courier so, I will not accept the order"), and send us a mail at <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text> mentioning your order reference number and attached pictures, or call our Customer Care. We will make our best efforts to ensure a replacement delivery is made to you at the earliest.
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold">Delivery Time:</Text>
            <Text mt={2}>
              Delivery time mentioned on the product, cart page, or website is estimated. Actual delivery time is based on the availability of a product, weather conditions, the address where the product is to be delivered, and the courier company's rules.
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold">Delivery Issues:</Text>
            <Text mt={2}>
              We try our best to get your product delivered. If for some reason the product is not delivered or is late, and due to that if any loss occurs to you, please note that Sonam Agrotech is not responsible. <br />
              <Text as="i">Note: If your address is in an ODA location, you will have to self-collect the parcel from the courier office. We will provide a courier office address and number. Our customer care executive will keep in touch with you.</Text>
            </Text>
          </ListItem>

          <ListItem>
            <Text fontWeight="bold">Contact Us:</Text>
            <Text mt={2}>
              For any issues in utilizing our services, you may contact our helpdesk on <a href="#" onClick={handlePhoneClick}> <Text as={"b"}>9142429858</Text></a> or mail us at <Text as={"b"}> <a href={mailtoLink}>{emailAddress}</a></Text>.
            </Text>
          </ListItem>
        </OrderedList>
      </Box>
    </Container></Box>
  );
}

export default ShippingPolicy;
