// PaymentSuccess.js
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  VStack,
  HStack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const PaymentSuccess = () => {
    const orderDetails = {
        orderId: '1234567890',
        productName: 'Chakra UI T-shirt',
        amountPaid: '29.99',
        date: '2024-05-24',
      };
    
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)"); // Media query for responsiveness

  return (
    <Flex
      align="center"
      justify="center"
      minH="100vh"
      bgGradient="linear(to-r, teal.500, green.500)"
      p={4}
    >
      <Box
        bg="white"
        p={6}
        borderRadius="lg"
        shadow="xl"
        maxW="md"
        w="full"
        textAlign="center"
      >
        <VStack spacing={4}>
          <CheckCircleIcon boxSize="50px" color="green.500" />
          <Heading as="h1" size="xl">
            Payment Successful!
          </Heading>
          <Text fontSize="lg" color="gray.600">
            Thank you for your purchase. Your order has been placed successfully.
          </Text>
          <Image
            src="https://via.placeholder.com/300"
            alt="Order Image"
            borderRadius="md"
            boxSize={isLargerThanMD ? "300px" : "100%"}
          />
          <VStack spacing={2} align="start" w="full">
            <Text fontSize="md" fontWeight="bold">
              Order Details:
            </Text>
            <HStack justify="space-between" w="full">
              <Text fontSize="sm" fontWeight="bold">
                Order ID:
              </Text>
              <Text fontSize="sm">{orderDetails.orderId}</Text>
            </HStack>
            <HStack justify="space-between" w="full">
              <Text fontSize="sm" fontWeight="bold">
                Product:
              </Text>
              <Text fontSize="sm">{orderDetails.productName}</Text>
            </HStack>
            <HStack justify="space-between" w="full">
              <Text fontSize="sm" fontWeight="bold">
                Amount Paid:
              </Text>
              <Text fontSize="sm">${orderDetails.amountPaid}</Text>
            </HStack>
            <HStack justify="space-between" w="full">
              <Text fontSize="sm" fontWeight="bold">
                Date:
              </Text>
              <Text fontSize="sm">{orderDetails.date}</Text>
            </HStack>
          </VStack>
          <Button colorScheme="teal" size="md" onClick={() => window.location.href = '/'}>
            Go to Home
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default PaymentSuccess;
